.header .navbar .navbar-brand {
  padding-left: 60px;
  background: url("../img/logo.png") 15px 80% no-repeat;
  background-size: contain;
}

/* Footer fixed at the bottom */
footer {
  background-color: #4caf50;
  flex-shrink: 0; /* Prevent shrinking */
  width: 100%; /* Full width */
  text-align: center; /* Optional: Center the footer content */
  padding: 10px 0; /* Optional: Add padding */
}

/* Navbar at the top */
nav.navbar {
  background-color: #4caf50;
  flex-shrink: 0; /* Prevent shrinking */

  .nav-link {
    color: #fff;
  }

  .active {
    color: #333333 !important;
  }
}
