/* Ensure consistent box-sizing */
html {
  box-sizing: border-box;
  height: 100%; /* Make the HTML element take the full height */
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

body {
  font-family: "Lato", "Helvetica", Arial, sans-serif;
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%; /* Now the body is a fixed 100% of the viewport height */
  height: 100%; /* Now the body is a fixed 100% of the viewport height */
}

/* Headings */
h1,
h2,
h3,
h4,
h5 {
  font-family: "PT Sans", Arial, sans-serif;
}

/* Root Variables */
:root {
  --p-datatable-row-selected-color: #fff !important;
  --p-datatable-row-selected-background: #4caf50 !important;
}

/* Main content area */
main {
  flex: 1 0 auto; /* Allow main to grow and take the available space */
  display: flex;
  flex-direction: column;
  overflow: auto;
}
