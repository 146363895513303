#manual {
  @media (min-width: 820px) {
    .sidenav {
      height: 100%;
      z-index: 2;
      float: left;
      top: 0;
      left: 0;
      background-color: #4caf50;
      overflow-x: hidden;
      padding-top: 60px;
      position: fixed;
      transition: 0.3s;
    }

    .dropdown-container {
      display: none;
      background-color: #4caf50;
      padding-left: 0px;
      border-top: 3px solid black;
      border-bottom: 3px solid black;
    }

    /* .width-sidenav{
    width: 200px
  } */
  }

  @media (max-width: 821px) {
    .sidenav {
      height: auto;
      z-index: 2;
      float: left;
      top: 0;
      left: 0;
      background-color: #4caf50;
      overflow-x: hidden;
      padding-top: 60px;
      position: absolute;
      transition: 0.3s;
    }

    .width-sidenav {
      width: 100%;
      display: none;
    }

    .dropdown-container {
      display: none;
      position: relative;
      background-color: #4caf50;
      padding-left: 0px;
      border-top: 3px solid black;
      border-bottom: 3px solid black;
    }
  }

  @media only screen and (max-width: 817px) and (min-width: 767px) {
    .sidenav {
      margin-top: 50px;
    }
  }

  .sidenav a,
  .dropdown-btn {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 16px;
    color: #ffffff;
    display: block;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: none;
  }

  .sidenav a:hover,
  .dropdown-btn:hover {
    color: black;
    transition: 0.3s;
  }

  .active {
    background-color: #f7f7f7;
    color: black;
  }

  #contents {
    height: 100%;
    width: 50%;
    margin: 10px auto;
    vertical-align: middle;
    /* margin-left: 10%; */
  }

  .sidepanel .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }

  .openbtn {
    font-size: 20px;
    cursor: pointer;
    background-color: #4caf50;
    color: rgba(0, 0, 0, 0.5);
    width: 45px;
    height: 45px;
    border: none;
    position: fixed;
  }

  .container-manual {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  /* Image zoom */
  /*#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
}

#myImg:hover {opacity: 0.7;}
 
.modal {
  display: none; 
  position: fixed;
  z-index: 10;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.9);
} */

  /* .modal-content {
  margin: auto;
  display: block;
  width: 75%;
  max-width: 75%;
}

.modal-content {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
} */

  .out {
    animation-name: zoom-out;
    animation-duration: 0.6s;
  }

  @-webkit-keyframes zoom {
    from {
      -webkit-transform: scale(1);
    }
    to {
      -webkit-transform: scale(2);
    }
  }

  @keyframes zoom {
    from {
      transform: scale(0.4);
    }
    to {
      transform: scale(1);
    }
  }

  @keyframes zoom-out {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(0);
    }
  }

  .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }

  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }

  @media only screen and (max-width: 700px) {
    .modal-content {
      width: 100%;
    }
  }
}
